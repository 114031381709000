import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import inProgressIllustration from '../images/in-progress.svg';

function IndexPage() {
    return (
        <Layout>
            <SEO
                keywords={['primatech', 'customer care', 'customer portal']}
                title='Primatech Care'
            />

            <section className='flex flex-col lg:flex-row relative max-w-6xl mx-auto mt-10 '>
                <div className='relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-xl w-full lg:w-full lg:pb-28 xl:pb-32 px-4 md:px-8'>
                    <h1 className='text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl'>
                        Primatech's <span className='text-primary'>customer care</span>
                    </h1>
                    <p className='mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0'>
                        We try to provide the best customers after sales experience directly to you
                        seamlessly
                    </p>
                </div>
                <div className='lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 px-4'>
                    <img src={inProgressIllustration} alt='journey' />
                </div>
            </section>
        </Layout>
    );
}

export default IndexPage;
